import { Component, Input } from '@angular/core';
import { NgClass, NgIf } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';

@Component({
  selector: 'app-password-strength',
  standalone: true,
  templateUrl: './password-strength.component.html',
  styleUrls: ['./password-strength.component.scss'],
  imports: [NgIf, NgClass, TranslateModule],
})
export class PasswordStrengthComponent {
  @Input() set password(val: string) {
    this.calculatePasswordStrength(val);
  }

  @Input() hideLine = false;

  atLeast8CharsAndMax30 = false;
  uppercaseChar = false;
  lowercaseChar = false;
  number = false;
  specialChar = false;

  level = 0;

  constructor() {}

  calculatePasswordStrength(val: string) {
    this.level = 0;
    this.atLeast8CharsAndMax30 = false;
    this.uppercaseChar = false;
    this.lowercaseChar = false;
    this.number = false;
    this.specialChar = false;

    if (val.length > 7 && val.length < 31) {
      this.atLeast8CharsAndMax30 = true;
      ++this.level;
    }
    if (this.containsUpper(val)) {
      this.uppercaseChar = true;
      ++this.level;
    }
    if (this.containsLower(val)) {
      this.lowercaseChar = true;
      ++this.level;
    }
    if (this.containsNumber(val)) {
      this.number = true;
      ++this.level;
    }

    if (this.containsSpecialChar(val)) {
      this.specialChar = true;
      ++this.level;
    }
  }

  containsSpecialChar(str: string): boolean {
    return /[@$!%*?&]/.test(str);
  }

  containsUpper(str: string): boolean {
    return /[A-Z]/.test(str);
  }

  containsLower(str: string): boolean {
    return /[a-z]/.test(str);
  }

  containsNumber(str: string): boolean {
    return /\d/.test(str);
  }
}
