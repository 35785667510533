<div class="level" *ngIf="!hideLine">
  <div [class]="'level-indicator level-' + level"></div>
</div>

<ng-container>
  <div class="item" [ngClass]="{ success: atLeast8CharsAndMax30 }">
    <ng-template
      [ngIf]="atLeast8CharsAndMax30"
      [ngIfThen]="success"
      [ngIfElse]="fail"
    ></ng-template>
    {{ 'PASSWORD_STRENGTH.AT_LEAST_8_CHARS_AND_MAX_30' | translate }}
  </div>
  <div class="item" [ngClass]="{ success: uppercaseChar }">
    <ng-template
      [ngIf]="uppercaseChar"
      [ngIfThen]="success"
      [ngIfElse]="fail"
    ></ng-template>
    {{ 'PASSWORD_STRENGTH.UPPERCASE' | translate }}
  </div>
  <div class="item" [ngClass]="{ success: lowercaseChar }">
    <ng-template
      [ngIf]="lowercaseChar"
      [ngIfThen]="success"
      [ngIfElse]="fail"
    ></ng-template>
    {{ 'PASSWORD_STRENGTH.LOWERCASE' | translate }}
  </div>
  <div class="item" [ngClass]="{ success: number }">
    <ng-template
      [ngIf]="number"
      [ngIfThen]="success"
      [ngIfElse]="fail"
    ></ng-template>
    {{ 'PASSWORD_STRENGTH.NUMBER' | translate }}
  </div>

  <div class="item" [ngClass]="{ success: specialChar }">
    <ng-template
      [ngIf]="specialChar"
      [ngIfThen]="success"
      [ngIfElse]="fail"
    ></ng-template>
    {{ 'PASSWORD_STRENGTH.SPECIAL_CHAR' | translate }}
  </div>

  <ng-template #success>
    <div class="item-icon">
      <svg
        width="12"
        height="9"
        viewBox="0 0 12 9"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M4.38377 8.99993C4.06714 9.00325 3.75051 8.88369 3.50645 8.64125L0.36819 5.51936C-0.118297 5.03613 -0.123245 4.24902 0.356646 3.75915C0.838186 3.26928 1.61986 3.2643 2.10635 3.74753L4.36563 5.99263L9.88355 0.369911C10.3634 -0.11996 11.1468 -0.123281 11.6333 0.359947C12.1181 0.843175 12.123 1.63029 11.6432 2.12016L5.25615 8.62962C5.01538 8.87539 4.7004 8.99827 4.38377 8.99993Z"
          fill="#3bd49d"
        />
      </svg>
    </div>
  </ng-template>

  <ng-template #fail> <div class="item-icon">&#x2022;</div></ng-template>
</ng-container>
